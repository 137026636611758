@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Futura-Condensed';
  src: url('../src/assets/fonts/Futura-Condensed-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura-Fat';
  src: url('../src/assets/fonts/Futura-Condensed-ExtraBold.ttf') format('truetype');
}

/* Loading Spinner Styling */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loader-container-small {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #00289d;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
